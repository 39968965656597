import React, {FunctionComponent} from "react";
import Layout from "../components/layout";
import {graphql} from "gatsby";
import { toUrl } from 'gatsby-source-gravatar'
import { Post, Author } from "../utils/models";
import Subheader from "../components/subheader";
import SEO from "../components/seo";
import Theme from "../styles/theme";
import PostGrid from "../components/post-grid";

interface CategoryTemplateProps {
  data: {
    author: Author;
    posts: {
      edges: Array<{ node: Post }>;
    }
  };
  location: Location;
}

const AuthorTemplate: FunctionComponent<CategoryTemplateProps> = ({data, location}) => {
  let author     = data.author;
  const posts = data.posts.edges.map(node => node.node);

  const avatarUrl = React.useMemo(() => toUrl(author.email, 'size=128'), [author.email]);

  if (!author && posts.length > 0) {
    author = {
      username: posts[0].frontmatter.author,
      email: 'sample@sample.com',
      bio: '',
      createdAt: '0',
      displayname: 'default'
      // color: Theme.layout.primaryColor,
      // icon: null,
      // featured: false,
    };
  }

  return (
    <Layout bigHeader={false}>
      <SEO
        title={author.displayname}
        location={location}
        type={`Series`}
      />
      <Subheader title={author.displayname} subtitle={author.bio} imageUrl={avatarUrl} backgroundColor={'#A6A6A6'}/>
      <PostGrid posts={posts} />
    </Layout>
  );
};

export default AuthorTemplate;

export const query = graphql`
  query($username: String) {
    author: authorsYaml(username: { eq: $username }) {
      bio
      email
      username
      displayname
    }
    posts: allMarkdownRemark(
      filter: {
        fileAbsolutePath: {regex: "/(posts)/.*\\\\.md$/"},
        frontmatter: {author: {eq: $username}}
      },
      sort: {fields: frontmatter___created, order: DESC}
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            path
            categories
            excerpt
            created
            createdPretty: created(formatString: "DD MMMM, YYYY")
          }
          featuredImage {
            childImageSharp {
              sizes(maxWidth: 800, quality: 100) {
                base64
                aspectRatio
                src
                srcSet
                sizes
              }
            }
          }
        }
      }
    }
  }
`;
